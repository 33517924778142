import React from "react";

export type TSlides = {
  imageName: string;
  title: JSX.Element;
  description: JSX.Element;
};

export const slides: TSlides[] = [
  {
    imageName: "oficinas",
    title: <>En Aerotec Publicidad</>,
    description: (
      <>
        Somos una empresa dedicada al diseño, desarrollo y elaboración de
        productos para tu publicidad con gran innovación y vanguardia en métodos
        de manufactura y diseños exclusivos, con materiales y equipo con
        tecnología de última generación.
      </>
    ),
  },
  {
    imageName: "maquinas",
    title: <>Nuestra misión</>,
    description: (
      <>
        Sostener el crecimiento en innovación de nuestros productos y servicios
        publicitarios, brindando soluciones creativas y de alto impacto
        incrementando tus ventas y posicionando tu marca.
      </>
    ),
  },
  {
    imageName: "manos",
    title: <>Nuestra visión</>,
    description: (
      <>
        Ejercer liderazgo, tanto nacional como internacional, a través de
        productos publicitarios de calidad, superando las expectativas de
        nuestros clientes y lograr con esto la mayor rentabilidad.
      </>
    ),
  },
  {
    imageName: "spark",
    title: <>Nuestros valores</>,
    description: (
      <>
        {" "}
        *Lealtad Valorar la relación comercial con nuestros clientes y
        provedores. *Compromiso Atender las necesidades con amabilidad y
        empatía. *Excelencia Generar una experiencia única a través de la
        manufactura de productos innovadores. *Exclusividad Plasmar la identidad
        de la marca de nuestros clientes en cada uno de nuestros productos.
      </>
    ),
  },
];
