import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Layout from "../components/layout/Layout";
import { ImageDataFile } from "../components/types";
import { slides, TSlides } from "../data/nosotros-slides";

interface SlideProps {
  image: ImageDataFile["gatsbyImageData"];
  title: React.ReactNode;
  description: React.ReactNode;
  activeIndex: number;
  index: number;
}

const len = slides.length - 1;

const Slide = ({
  image,
  title,
  description,
  activeIndex,
  index,
}: SlideProps) => {
  const img = getImage(image);

  return (
    <div
      className={`w-full h-full overflow-hidden relative ${
        activeIndex === index ? "flex" : "hidden"
      }`}
    >
      <GatsbyImage
        image={img!}
        alt="Aerotec"
        className="absolute top-0 right-0 w-full h-full object-cover"
      />
      <div className="w-full h-full z-10 absolute pt-20 flex justify-center items-center slide-contnent-container">
        <div className="w-11/12 max-w-3xl text-center slide-content px-8 py-8 rounded-xl">
          <h2 className="mb-2 text-3xl font-bold md:text-5xl">{title}</h2>
          <p className="slide-description md:text-xl">{description}</p>
        </div>
      </div>
    </div>
  );
};

const Slider = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="w-screen h-screen overflow-hidden relative z-10 flex justify-center">
      <SliderContent activeIndex={activeIndex} slides={slides} />
      <Arrows
        prev={() => setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)}
        next={() => setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)}
      />
      <Dots
        activeIndex={activeIndex}
        sliderImage={slides}
        onClick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
};

const Arrows = (props: {
  next: React.MouseEventHandler<HTMLSpanElement>;
  prev: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  return (
    <div className="-z-1 flex items-center absolute h-full w-full">
      <button
        className="control-slide-button prev"
        onClick={props.prev}
        aria-label="ver-vista-anterior"
      >
        <FaChevronLeft className="h-10 w-10" />
      </button>
      <button
        className="control-slide-button next"
        onClick={props.next}
        aria-label="ver-vista-sigueinte"
      >
        <FaChevronRight className="h-10 w-10" />
      </button>
    </div>
  );
};

const Dots = (props: {
  activeIndex: number;
  onClick: (n: number) => void;
  sliderImage: TSlides[];
}) => {
  return (
    <div className="control-slide-dots z-50 w-full absolute bottom-5 flex justify-center items-center">
      <div className="">
        {props.sliderImage.map((slide, index) => (
          <button
            key={index}
            className={`control-slide-dot ${
              props.activeIndex === index ? "active" : ""
            }`}
            onClick={() => props.onClick(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

const SliderContent = (props: { activeIndex: number; slides: TSlides[] }) => {
  const data = useStaticQuery<any>(graphql`
    {
      oficinas: file(relativePath: { eq: "oficinas.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      manos: file(relativePath: { eq: "aerotec-manos.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      maquinas: file(relativePath: { eq: "aerotec-maquinas.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      spark: file(relativePath: { eq: "aerotec-spark.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `);

  return (
    <>
      {props.slides.map((slide, index) => (
        <Slide
          index={index}
          activeIndex={props.activeIndex}
          image={data[slide.imageName]}
          title={slide.title}
          description={slide.description}
        />
      ))}
    </>
  );
};

// markup
const NosotrosPage = () => {
  return (
    <Layout>
      <Slider />
    </Layout>
  );
};

export default NosotrosPage;
